import React from "react";

export const Navigation = (props) => {
  const [lang, setLang] = React.useState(localStorage.getItem("lang") || "en");
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      <div className="container">
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="#page-top">
            Mushtic
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="#features" className="page-scroll">
                Benefits
              </a>
            </li>
            {/* <li>
              <a href="#about" className="page-scroll">
                About
              </a>
            </li> */}
            <li>
              <a href="#services" className="page-scroll">
                Vision
              </a>
            </li>
            {/* <li>
              <a href="#portfolio" className="page-scroll">
                Gallery
              </a>
            </li> */}
            {/* <li>
              <a href="#testimonials" className="page-scroll">
                Testimonials
              </a>
            </li> */}
            {/* <li>
              <a href="#team" className="page-scroll">
                Team
              </a>
            </li> */}
            <li>
              <a href="#contact" className="page-scroll">
                Contact
              </a>
            </li>
            {lang !== "en" && (
              <li>
                <a>
                  <div>
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        localStorage.setItem("lang", "en");
                        window.location.reload();
                      }}
                    >
                      EN
                    </a>
                  </div>
                </a>
              </li>
            )}
            {lang !== "fr" && (
              <li>
                <a>
                  <div>
                    <a
                      href="javascript:void(0)"
                      onClick={() => {
                        localStorage.setItem("lang", "fr");
                        window.location.reload();
                      }}
                    >
                      FR
                    </a>
                  </div>
                </a>
              </li>
            )}
          </ul>
        </div>
      </div>
    </nav>
  );
};
